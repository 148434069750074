
<template>

    <transition name="modal-fade">
        <div class="modal-backdrop" v-on:click="closeModal()">
            <GoToQuizModal v-if="viewGoToQuizModal" @closeModal="toggleGoToQuizModal()" />

            <div class="modal" v-on:click.stop=""  role="dialog"  aria-labelledby="modalTitle"  aria-describedby="modalDescription">
                <header class="modal-header" id="modalTitle">
                    <button type="button" class="btn-close" @click="closeModal()" aria-label="Close modal">
                        x
                    </button>
                </header>

                <div class="modal-body" id="modalDescription">
                    <div class="row">
                        <div class="col-12">
                           <video id="video" autoplay style="width: 100%" controls>
                                <source :src="require(`@/assets/video/${video}.mp4`)" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import GoToQuizModal from "@/components/GoToQuizModal.vue"

	export default {
		name: "PlayVideoModal",
		data() {
			return {
                viewGoToQuizModal: false,
            }
		},

        components: { GoToQuizModal },

        mounted() {
            var myPlayer = document.querySelector('#video');
            var percentageCompleted = 0;
            var totalLength;
            var videoStarted, videoFifty, videoComplete = false;
            var chapter = this.video.slice(-1)

            const self = this

            myPlayer.ontimeupdate = function() {
                getPercentage(self)
            };

            function getPercentage(self) {
                totalLength = myPlayer.duration;
                percentageCompleted = (myPlayer.currentTime / totalLength) * 100;

                // is 0
                if ((!videoStarted) && (percentageCompleted > 0)) {
                    console.log('VIDEO_STARTED');
                    videoStarted = true;

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'video' + chapter + 'start'
                    });
                }

                // is 50
                if ((!videoFifty) && (percentageCompleted > 50)) {
                    console.log('VIDEO_50');
                    videoFifty = true;

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'video' + chapter + 'half'
                    });
                }

                // is 100
                if ((!videoComplete) && (percentageCompleted > 99)) {
                    console.log('VIDEO_100');
                    videoComplete = true;

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'video' + chapter + 'all'
                    });

                    if (chapter == '5') {
                        self.toggleGoToQuizModal()
                    }
                }

            }
        },

        props: ["video"],

		emits: ["closeModal"],

        methods: {
            toggleGoToQuizModal() {
                console.log("toglging")
                this.viewGoToQuizModal = !this.viewGoToQuizModal
            },

            closeModal() {
                this.$emit("closeModal")
            },
        }
    }
</script>



<style lang="sass" scoped>
.modal-backdrop
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, 0.25)
    display: flex
    justify-content: center
    align-items: center
    z-index: 999
  

.modal 
    background: #333
    overflow-x: auto
    display: flex
    flex-direction: column
    min-width: 700px
    max-width: 50vw
    height: auto
    padding: 50px 40px
    @media (max-width: 700px)
        min-width: 0
        max-width: none

  

.modal-header,
.modal-footer 
    padding: 0
    display: flex
  

.modal-header 
    position: relative
    justify-content: space-between
    padding: 30px
  

.modal-footer 
    border-top: 1px solid #eeeeee
    flex-direction: column
  

.modal-body 
    position: relative  

.btn-close 
    position: absolute
    top: -40px
    right: -20px
    border: none
    font-size: 40px
    padding: 10px
    cursor: pointer
    font-weight: 100
    color: #B17643
    background: transparent

  

.modal-fade-enter,
.modal-fade-leave-to 
    opacity: 0
  

.modal-fade-enter-active,
.modal-fade-leave-active 
    transition: opacity .5s ease


</style>